// import React, { useState, useEffect, useRef, useMemo } from 'react';
//
// const DNALetter = ({ finalLetter, isVisible, isTitle }) => {
//   const [currentLetter, setCurrentLetter] = useState(' ');
//   const dnaLetters = useMemo(() => ['A', 'T', 'C', 'G'], []);
//
//   useEffect(() => {
//     if (!isVisible) return;
//
//     let interval;
//     let counter = 0;
//     const maxIterations = 10 + Math.random() * 10;
//
//     interval = setInterval(() => {
//       if (counter < maxIterations) {
//         setCurrentLetter(dnaLetters[Math.floor(Math.random() * dnaLetters.length)]);
//         counter++;
//       } else {
//         setCurrentLetter(finalLetter);
//         clearInterval(interval);
//       }
//     }, 50);
//
//     return () => clearInterval(interval);
//   }, [finalLetter, dnaLetters, isVisible]);
//
//   return (
//     <span className={isTitle ? "inline-block transition-all duration-300 hover:text-[#1518e1] hover:-translate-y-2 hover:scale-110" : "inline-block"}>
//       {currentLetter}
//     </span>
//   );
// };
//
// const AnimatedText = ({ text, className, isTitle = false }) => {
//   const [isVisible, setIsVisible] = useState(false);
//   const ref = useRef(null);
//
//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           setIsVisible(true);
//           observer.unobserve(entry.target);
//         }
//       },
//       { threshold: 0.1 }
//     );
//
//     if (ref.current) {
//       observer.observe(ref.current);
//     }
//
//     return () => {
//       if (ref.current) {
//         observer.unobserve(ref.current);
//       }
//     };
//   }, []);
//
//   return (
//     <span ref={ref} className={className}>
//       {text.split('').map((char, index) => (
//         char === ' ' ? (
//           <span key={index}>&nbsp;</span>
//         ) : (
//           <DNALetter key={index} finalLetter={char} isVisible={isVisible} isTitle={isTitle} />
//         )
//       ))}
//     </span>
//   );
// };
//
// function App() {
//   const [isVisible, setIsVisible] = useState(false);
//   const [isDarkTheme, setIsDarkTheme] = useState(false);
//   const [currentPage, setCurrentPage] = useState('home');
//   const titleWords = ["Cosmid", "Bio"];
//   const subtitle = "Digital tools for the natural world";
//   const canvasRef = useRef(null);
//
//   useEffect(() => {
//     setIsVisible(true);
//   }, []);
//
//   useEffect(() => {
//     if (currentPage === 'home' && canvasRef.current) {
//       const canvas = canvasRef.current;
//       const ctx = canvas.getContext('2d');
//       let animationFrameId;
//
//       canvas.width = window.innerWidth;
//       canvas.height = window.innerHeight;
//
//       const particles = [];
//
//       class Particle {
//         constructor() {
//           this.x = Math.random() * canvas.width;
//           this.y = Math.random() * canvas.height;
//           this.size = Math.random() * 2 + 1;
//           this.speedX = Math.random() * 3 - 1.5;
//           this.speedY = Math.random() * 3 - 1.5;
//         }
//
//         update() {
//           this.x += this.speedX;
//           this.y += this.speedY;
//
//           if (this.x < 0 || this.x > canvas.width) this.speedX *= -1;
//           if (this.y < 0 || this.y > canvas.height) this.speedY *= -1;
//
//           if (this.size > 0.2) this.size -= 0.01;
//         }
//
//         draw() {
//           ctx.fillStyle = isDarkTheme ? 'rgba(165, 167, 243, 0.5)' : 'rgba(21, 24, 225, 0.5)';
//           ctx.beginPath();
//           ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
//           ctx.fill();
//         }
//       }
//
//       function createParticles() {
//         for (let i = 0; i < 200; i++) {
//           particles.push(new Particle());
//         }
//       }
//
//       function animateParticles() {
//         ctx.clearRect(0, 0, canvas.width, canvas.height);
//         for (let i = 0; i < particles.length; i++) {
//           particles[i].update();
//           particles[i].draw();
//           
//           if (particles[i].size <= 0.2) {
//             particles[i] = new Particle();
//           }
//         }
//         animationFrameId = requestAnimationFrame(animateParticles);
//       }
//
//       createParticles();
//       animateParticles();
//
//       return () => {
//         cancelAnimationFrame(animationFrameId);
//       }
//     }
//   }, [isDarkTheme, currentPage]);
//
//   const toggleTheme = () => {
//     setIsDarkTheme(!isDarkTheme);
//   };
//
//   const goToNewPage = () => {
//     setCurrentPage('new');
//   };
//
//   const goToHome = () => {
//     setCurrentPage('home');
//   };
//
//   const goToContactPage = () => {
//     setCurrentPage('contact');
//   };
//
//   const goToJoinUsPage = () => {
//     setCurrentPage('join');
//   };
//
//   const DropdownBox = ({ title, content }) => {
//     const [isOpen, setIsOpen] = useState(false);
//     const contentRef = useRef(null);
//
//     return (
//       <div className={`w-full mb-4 border rounded-lg overflow-hidden ${isDarkTheme ? 'border-gray-600' : 'border-gray-300'}`}>
//         <button
//           className={`w-full p-6 text-left font-bold text-xl focus:outline-none ${isDarkTheme ? 'bg-gray-800 text-white' : 'bg-gray-100 text-black'}`}
//           onClick={() => setIsOpen(!isOpen)}
//         >
//           {title}
//           <span className="float-right transition-transform duration-300" style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>▼</span>
//         </button>
//         <div 
//           ref={contentRef}
//           className={`overflow-hidden transition-all duration-300 ease-in-out ${isDarkTheme ? 'bg-gray-700 text-gray-300' : 'bg-white text-gray-700'}`}
//           style={{ 
//             maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0',
//             opacity: isOpen ? 1 : 0,
//             transform: isOpen ? 'translateY(0)' : 'translateY(-20px)'
//           }}
//         >
//           <div className="p-6 text-lg">
//             {content}
//           </div>
//         </div>
//       </div>
//     );
//   };
//
//   const NewPage = () => (
//     <div className={`flex flex-col items-center min-h-screen w-full ${isDarkTheme ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
//       <div className="absolute top-4 left-4 z-30">
//         <button onClick={goToHome} className={`text-2xl font-bold hover:text-[#1518e1] ${isDarkTheme ? 'text-white' : 'text-black'}`}>
//           <AnimatedText text="Cosmid Bio" />
//         </button>
//       </div>
//       <button 
//         onClick={toggleTheme} 
//         className="absolute top-4 right-4 z-30 px-4 py-2 bg-transparent text-[#1518e1] border border-[#1518e1] rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300"
//       >
//         {isDarkTheme ? '☀️' : '🌙'}
//       </button>
//       <h1 className="text-4xl mb-8 mt-16"><AnimatedText text="Projects" /></h1>
//       <div className="w-full max-w-4xl px-4 flex flex-col items-center">
//         <DropdownBox 
//           title={<AnimatedText text="Data Processing Group" />}
//           content={<AnimatedText text="Developing methods to handle, work with, and process biological data." />}
//         />
//         <DropdownBox 
//           title={<AnimatedText text="Computer Vision Group" />}
//           content={<AnimatedText text="Applying visual learning models to biological and neural systems." />}
//         />
//         <DropdownBox 
//           title={<AnimatedText text="Language Group" />}
//           content={<AnimatedText text="Bringing language processing to life sciences. Interested in leading this group? Visit our 'Join Us' page." />}
//         />
//       </div>
//       <div className={`mt-8 text-center max-w-2xl px-4 ${isDarkTheme ? 'text-gray-300' : 'text-gray-700'}`}>
//         <h2 className={`text-2xl font-bold mb-4 ${isDarkTheme ? 'text-white' : 'text-black'}`}>
//           <AnimatedText text="Collaboration" />
//         </h2>
//         <p>
//           <AnimatedText text="Cosmid Bio is a student-run open source lab that is always looking for contributors! Ch- eck out our" />
//           {' '}
//           <a 
//             href="https://discord.gg/FHSf5wmU" 
//             target="_blank" 
//             rel="noopener noreferrer" 
//             className={`text-[#1518e1] hover:underline ${isDarkTheme ? 'hover:text-blue-400' : 'hover:text-blue-600'}`}
//           >
//             <AnimatedText text="Discord" />
//           </a>
//           {' '}
//           <AnimatedText text="for information on collaboration and our community." />
//         </p>
//       </div>
//     </div>
//   );
//
//   const ContactPage = () => {
//     const [submitted, setSubmitted] = useState(false);
//
//     const handleSubmit = (e) => {
//       e.preventDefault();
//       const form = e.target;
//       const data = new FormData(form);
//       
//       fetch("https://formspree.io/f/mldrjbov", {
//         method: "POST",
//         body: data,
//         headers: {
//           'Accept': 'application/json'
//         }
//       }).then(response => {
//         if (response.ok) {
//           setSubmitted(true);
//           form.reset();
//         } else {
//           response.json().then(data => {
//             if (Object.hasOwn(data, 'errors')) {
//               console.log(data["errors"].map(error => error["message"]).join(", "))
//             } else {
//               console.log('Oops! There was a problem submitting your form')
//             }
//           })
//         }
//       }).catch(error => {
//         console.log('Oops! There was a problem submitting your form', error)
//       });
//     };
//
//     return (
//       <div className={`flex flex-col items-center min-h-screen w-full ${isDarkTheme ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
//         <div className="absolute top-4 left-4 z-30">
//           <button onClick={goToHome} className={`text-2xl font-bold hover:text-[#1518e1] ${isDarkTheme ? 'text-white' : 'text-black'}`}>
//             <AnimatedText text="Cosmid Bio" />
//           </button>
//         </div>
//         <button 
//           onClick={toggleTheme} 
//           className="absolute top-4 right-4 z-30 px-4 py-2 bg-transparent text-[#1518e1] border border-[#1518e1] rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300"
//         >
//           {isDarkTheme ? '☀️' : '🌙'}
//         </button>
//         <h1 className="text-4xl mb-8 mt-16"><AnimatedText text="Contact Us" /></h1>
//         <div className="w-full max-w-2xl px-4 text-center">
//           <p className="mb-8">
//             <AnimatedText text="We'd love to hear from you! Please reach out to any of our core team members through our " />
//             <a 
//               href="https://discord.gg/FHSf5wmU" 
//               target="_blank" 
//               rel="noopener noreferrer" 
//               className={`text-[#1518e1] hover:underline ${isDarkTheme ? 'hover:text-blue-400' : 'hover:text-blue-600'}`}
//             >
//               <AnimatedText text="Discord" />
//             </a>
//             <AnimatedText text=". Alternatively, you can send us an email here." />
//           </p>
//           {submitted ? (
//             <p className="text-green-500"><AnimatedText text="Thanks for your message! We'll get back to you soon." /></p>
//           ) : (
//             <form onSubmit={handleSubmit} className="flex flex-col items-center">
//               <input 
//                 type="email" 
//                 name="email"
//                 placeholder="Your email"
//                 required
//                 className={`w-full p-2 mb-4 rounded ${isDarkTheme ? 'bg-gray-700 text-white' : 'bg-gray-200 text-black'}`}
//               />
//               <textarea 
//                 name="message"
//                 placeholder="Your message"
//                 required
//                 rows="4"
//                 className={`w-full p-2 mb-4 rounded ${isDarkTheme ? 'bg-gray-700 text-white' : 'bg-gray-200 text-black'}`}
//               ></textarea>
//               <button 
//                 type="submit"
//                 className={`px-4 py-2 bg-transparent border rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300 ${isDarkTheme ? 'text-white border-white' : 'text-[#1518e1] border-[#1518e1]'}`}
//               >
//                 <AnimatedText text="Send Message" />
//               </button>
//             </form>
//           )}
//         </div>
//       </div>
//     );
//   };
//
//   const JoinUsPage = () => {
//     return (
//       <div className={`flex flex-col items-center min-h-screen w-full ${isDarkTheme ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
//         <div className="absolute top-4 left-4 z-30">
//           <button onClick={goToHome} className={`text-2xl font-bold hover:text-[#1518e1] ${isDarkTheme ? 'text-white' : 'text-black'}`}>
//             <AnimatedText text="Cosmid Bio" />
//           </button>
//         </div>
//         <button 
//           onClick={toggleTheme} 
//           className="absolute top-4 right-4 z-30 px-4 py-2 bg-transparent text-[#1518e1] border border-[#1518e1] rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300"
//         >
//           {isDarkTheme ? '☀️' : '🌙'}
//         </button>
//
//         <h1 className="text-4xl mb-4 mt-16"><AnimatedText text="Join Our Team" /></h1>
//         <h2 className="text-2xl mb-8"><AnimatedText text="Open Positions" /></h2>
//         <div className="w-full max-w-4xl px-4">
//           <h3 className="text-2xl mb-4"><AnimatedText text="Core Team Member" /></h3>
//           <ul className="list-disc list-inside space-y-4 mb-8">
//             <li><AnimatedText text="Proficient in Python as well as (optionally) a systems programming language (preferably C, C++, or Rust)." /></li>
//             <li><AnimatedText text="Proficient in (some) relevant Python libraries for machine learning (PyTorch, Tensorflow, Keras, scikit-learn, etc.) and life sciences. " /></li>
//             <li><AnimatedText text="Familiar with advanced concepts in life sciences." /></li>
//             <li><AnimatedText text="Familiar with relevant technologies in software development (Git, GitHub, etc.)" /></li>
//           </ul>
//           <p className="text-lg">
//             <AnimatedText text="We welcome any student to contribute to our projcts, but we are also always in search of core team members. A position on the core team comes with more responsibility and commitment to projects. If interested, please apply " />
//             <a 
//               href="https://docs.google.com/forms/d/e/1FAIpQLSdFhFuRXU15zPknviux5oXCgJRf3si7Qpu-KnTp0b0vn-pEng/viewform" 
//               target="_blank" 
//               rel="noopener noreferrer" 
//               className={`text-[#1518e1] hover:underline ${isDarkTheme ? 'hover:text-blue-400' : 'hover:text-blue-600'}`}
//             >
//               <AnimatedText text="here" />
//             </a>
//             <AnimatedText text=". For general contributions, please visit our Discord." />
//           </p>
//         </div>
//       </div>
//     );
//   };
//
//   return (
//     <>
//       {currentPage === 'home' ? (
//         <div className={`flex flex-col items-center justify-center min-h-screen relative overflow-hidden ${isDarkTheme ? 'bg-gray-900' : 'bg-white'}`}>
//           <div className="absolute inset-0 w-full h-full z-0 filter blur-sm">
//             <svg className="w-full h-full opacity-60" xmlns="http://www.w3.org/2000/svg">
//               <defs>
//                 <pattern id="complexPattern" width="200" height="200" patternUnits="userSpaceOnUse" patternTransform="rotate(-20) scale(1.4)">
//                   <path d="M0,100 L50,0 M0,100 L50,200 M100,0 L150,100 M100,200 L150,100
//                            M50,0 L150,100 M50,200 L150,100" 
//                         stroke={isDarkTheme ? '#a5a7f3' : '#1518e1'} 
//                         strokeWidth="1"
//                         fill="none"/>
//                 </pattern>
//               </defs>
//               <rect width="100%" height="100%" fill="url(#complexPattern)" />
//             </svg>
//           </div>
//           <canvas ref={canvasRef} className="absolute inset-0 z-10" />
//           <button 
//             onClick={toggleTheme} 
//             className="absolute top-4 right-4 z-30 px-4 py-2 bg-transparent text-[#1518e1] border border-[#1518e1] rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300"
//           >
//             {isDarkTheme ? '☀️' : '🌙'}
//           </button>
//           <div className={`relative z-20 text-center flex flex-col items-center transition-opacity duration-1000 ease-in ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
//             <h1 className={`text-8xl font-bold mb-2 font-sans flex ${isDarkTheme ? 'text-white' : 'text-black'}`}>
//               {titleWords.map((word, wordIndex) => (
//                 <React.Fragment key={wordIndex}>
//                   {wordIndex > 0 && <span className="mx-2.5"> </span>}
//                   <AnimatedText text={word} className="inline-block" isTitle={true} />
//                 </React.Fragment>
//               ))}
//             </h1>
//             <AnimatedText 
//               text={subtitle}
//               className={`text-2xl font-sans mb-8 ${isDarkTheme ? 'text-gray-300' : 'text-black'}`}
//             />
//             <div className="flex space-x-4">
//               <button onClick={goToNewPage} className={`px-4 py-2 bg-transparent border rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300 hover:-translate-y-1 ${isDarkTheme ? 'text-white border-white' : 'text-[#1518e1] border-[#1518e1]'}`}>
//                 <AnimatedText text="Projects" />
//               </button>
//               <button onClick={goToJoinUsPage} className={`px-4 py-2 bg-transparent border rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300 hover:-translate-y-1 ${isDarkTheme ? 'text-white border-white' : 'text-[#1518e1] border-[#1518e1]'}`}>
//                 <AnimatedText text="Join Us" />
//               </button>
//               <button onClick={goToContactPage} className={`px-4 py-2 bg-transparent border rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300 hover:-translate-y-1 ${isDarkTheme ? 'text-white border-white' : 'text-[#1518e1] border-[#1518e1]'}`}>
//                 <AnimatedText text="Contact Us" />
//               </button>
//             </div>
//           </div>
//         </div>
//       ) : currentPage === 'new' ? (
//         <NewPage />
//       ) : currentPage === 'contact' ? (
//         <ContactPage />
//       ) : (
//         <JoinUsPage />
//       )}
//     </>
//   );
// }
//
// export default App;


import React, { useState, useEffect, useRef, useMemo } from 'react';

// Add this CSS to your global styles or as a styled component
const globalStyles = `
  .no-break {
    white-space: nowrap;
  }
  .hyphenate {
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
  }
`;

const DNALetter = ({ finalLetter, isVisible, isTitle }) => {
  const [currentLetter, setCurrentLetter] = useState(' ');
  const dnaLetters = useMemo(() => ['A', 'T', 'C', 'G'], []);

  useEffect(() => {
    if (!isVisible) return;

    let interval;
    let counter = 0;
    const maxIterations = 10 + Math.random() * 10;

    interval = setInterval(() => {
      if (counter < maxIterations) {
        setCurrentLetter(dnaLetters[Math.floor(Math.random() * dnaLetters.length)]);
        counter++;
      } else {
        setCurrentLetter(finalLetter);
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [finalLetter, dnaLetters, isVisible]);

  return (
    <span className={isTitle ? "inline-block transition-all duration-300 hover:text-[#1518e1] hover:-translate-y-2 hover:scale-110" : "inline-block"}>
      {currentLetter}
    </span>
  );
};

const AnimatedText = ({ text, className, isTitle = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

//   return (
//     <span ref={ref} className={`${className} no-break`}>
//       {text.split(' ').map((word, wordIndex) => (
//         <span key={wordIndex} className="inline-block whitespace-nowrap">
//           {word.split('').map((char, charIndex) => (
//             <DNALetter key={charIndex} finalLetter={char} isVisible={isVisible} isTitle={isTitle} />
//           ))}
//           {wordIndex < text.split(' ').length - 1 && ' '}
//         </span>
//       ))}
//     </span>
//   );
// };

  return (
    <span ref={ref} className={className}>
      {text.split(' ').map((word, wordIndex) => (
        <React.Fragment key={wordIndex}>
          <span className="inline-block whitespace-nowrap">
            {word.split('').map((char, charIndex) => (
              <DNALetter key={charIndex} finalLetter={char} isVisible={isVisible} isTitle={isTitle} />
            ))}
          </span>
          {wordIndex < text.split(' ').length - 1 && ' '}
        </React.Fragment>
      ))}
    </span>
  );
};

function App() {
  const [isVisible, setIsVisible] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [currentPage, setCurrentPage] = useState('home');
  const titleWords = ["Cosmid", "Bio"];
  const subtitle = "Digital tools for the natural world";
  const canvasRef = useRef(null);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    if (currentPage === 'home' && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      let animationFrameId;

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      const particles = [];

      class Particle {
        constructor() {
          this.x = Math.random() * canvas.width;
          this.y = Math.random() * canvas.height;
          this.size = Math.random() * 2 + 1;
          this.speedX = Math.random() * 3 - 1.5;
          this.speedY = Math.random() * 3 - 1.5;
        }

        update() {
          this.x += this.speedX;
          this.y += this.speedY;

          if (this.x < 0 || this.x > canvas.width) this.speedX *= -1;
          if (this.y < 0 || this.y > canvas.height) this.speedY *= -1;

          if (this.size > 0.2) this.size -= 0.01;
        }

        draw() {
          ctx.fillStyle = isDarkTheme ? 'rgba(165, 167, 243, 0.5)' : 'rgba(21, 24, 225, 0.5)';
          ctx.beginPath();
          ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
          ctx.fill();
        }
      }

      function createParticles() {
        for (let i = 0; i < 200; i++) {
          particles.push(new Particle());
        }
      }

      function animateParticles() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        for (let i = 0; i < particles.length; i++) {
          particles[i].update();
          particles[i].draw();
          
          if (particles[i].size <= 0.2) {
            particles[i] = new Particle();
          }
        }
        animationFrameId = requestAnimationFrame(animateParticles);
      }

      createParticles();
      animateParticles();

      return () => {
        cancelAnimationFrame(animationFrameId);
      }
    }
  }, [isDarkTheme, currentPage]);

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const goToNewPage = () => {
    setCurrentPage('new');
  };

  const goToHome = () => {
    setCurrentPage('home');
  };

  const goToContactPage = () => {
    setCurrentPage('contact');
  };

  const goToJoinUsPage = () => {
    setCurrentPage('join');
  };

  const DropdownBox = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);

    return (
      <div className={`w-full mb-4 border rounded-lg overflow-hidden ${isDarkTheme ? 'border-gray-600' : 'border-gray-300'}`}>
        <button
          className={`w-full p-6 text-left font-bold text-xl focus:outline-none ${isDarkTheme ? 'bg-gray-800 text-white' : 'bg-gray-100 text-black'}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {title}
          <span className="float-right transition-transform duration-300" style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>▼</span>
        </button>
        <div 
          ref={contentRef}
          className={`overflow-hidden transition-all duration-300 ease-in-out ${isDarkTheme ? 'bg-gray-700 text-gray-300' : 'bg-white text-gray-700'}`}
          style={{ 
            maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0',
            opacity: isOpen ? 1 : 0,
            transform: isOpen ? 'translateY(0)' : 'translateY(-20px)'
          }}
        >
          <div className="p-6 text-lg hyphenate">
            {content}
          </div>
        </div>
      </div>
    );
  };

  const NewPage = () => (
    <div className={`flex flex-col items-center min-h-screen w-full ${isDarkTheme ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
      <div className="absolute top-4 left-4 z-30">
        <button onClick={goToHome} className={`text-2xl font-bold hover:text-[#1518e1] ${isDarkTheme ? 'text-white' : 'text-black'}`}>
          <AnimatedText text="Cosmid Bio" />
        </button>
      </div>
      <button 
        onClick={toggleTheme} 
        className="absolute top-4 right-4 z-30 px-4 py-2 bg-transparent text-[#1518e1] border border-[#1518e1] rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300"
      >
        {isDarkTheme ? '☀️' : '🌙'}
      </button>
      <h1 className="text-4xl mb-8 mt-16"><AnimatedText text="Projects" /></h1>
      <div className="w-full max-w-4xl px-4 flex flex-col items-center">
        <DropdownBox 
          title={<AnimatedText text="Neurons & Cognition Group" />}
          content={<AnimatedText text="Studying systems of thought, computation, and perception as well as their detriments." />}
        />
        <DropdownBox 
          title={<AnimatedText text="Computer Vision Group" />}
          content={<AnimatedText text="Applying visual learning models to biological and neural systems." />}
        />
        <DropdownBox 
          title={<AnimatedText text="Data Processing Group" />}
          content={<AnimatedText text="Developing methods to handle, work with, and process biological data. Interested in leading this group? Visit our 'Join Us' page for more information." />}
        />
      </div>
      <div className={`mt-8 text-center max-w-2xl px-4 ${isDarkTheme ? 'text-gray-300' : 'text-gray-700'}`}>
        <h2 className={`text-2xl font-bold mb-4 ${isDarkTheme ? 'text-white' : 'text-black'}`}>
          <AnimatedText text="Collaboration" />
        </h2>
        <p className="hyphenate">
          <AnimatedText text="Cosmid Bio is a student-run open source lab that is always looking for contributors! Check out our" />
          {' '}
          <a 
            href="https://discord.gg/FHSf5wmU" 
            target="_blank" 
            rel="noopener noreferrer" 
            className={`text-[#1518e1] hover:underline ${isDarkTheme ? 'hover:text-blue-400' : 'hover:text-blue-600'}`}
          >
            <AnimatedText text="Discord" />
          </a>
          {' '}
          <AnimatedText text="for information on collaboration and our community." />
        </p>
      </div>
    </div>
  );

  const ContactPage = () => {
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
      e.preventDefault();
      const form = e.target;
      const data = new FormData(form);
      
      fetch("https://formspree.io/f/mldrjbov", {
        method: "POST",
        body: data,
        headers: {
          'Accept': 'application/json'
        }
      }).then(response => {
        if (response.ok) {
          setSubmitted(true);
          form.reset();
        } else {
          response.json().then(data => {
            if (Object.hasOwn(data, 'errors')) {
              console.log(data["errors"].map(error => error["message"]).join(", "))
            } else {
              console.log('Oops! There was a problem submitting your form')
            }
          })
        }
      }).catch(error => {
        console.log('Oops! There was a problem submitting your form', error)
      });
    };

    return (
      <div className={`flex flex-col items-center min-h-screen w-full ${isDarkTheme ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
        <div className="absolute top-4 left-4 z-30">
          <button onClick={goToHome} className={`text-2xl font-bold hover:text-[#1518e1] ${isDarkTheme ? 'text-white' : 'text-black'}`}>
            <AnimatedText text="Cosmid Bio" />
          </button>
        </div>
        <button 
          onClick={toggleTheme} 
          className="absolute top-4 right-4 z-30 px-4 py-2 bg-transparent text-[#1518e1] border border-[#1518e1] rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300"
        >
          {isDarkTheme ? '☀️' : '🌙'}
        </button>
        <h1 className="text-4xl mb-8 mt-16"><AnimatedText text="Contact Us" /></h1>
        <div className="w-full max-w-2xl px-4 text-center">
          <p className="mb-8 hyphenate">
            <AnimatedText text="We'd love to hear from you! Please reach out to any of our core team members through our " />
            <a 
              href="https://discord.gg/FHSf5wmU" 
              target="_blank" 
              rel="noopener noreferrer" 
              className={`text-[#1518e1] hover:underline ${isDarkTheme ? 'hover:text-blue-400' : 'hover:text-blue-600'}`}
            >
              <AnimatedText text="Discord" />
            </a>
            <AnimatedText text=". Alternatively, you can send us an email here." />
          </p>
          {submitted ? (
            <p className="text-green-500"><AnimatedText text="Thanks for your message! We'll get back to you soon." /></p>
          ) : (
            <form onSubmit={handleSubmit} className="flex flex-col items-center">
              <input 
                type="email" 
                name="email"
                placeholder="Your email"
                required
                className={`w-full p-2 mb-4 rounded ${isDarkTheme ? 'bg-gray-700 text-white' : 'bg-gray-200 text-black'}`}
              />
              <textarea 
                name="message"
                placeholder="Your message"
                required
                rows="4"
                className={`w-full p-2 mb-4 rounded ${isDarkTheme ? 'bg-gray-700 text-white' : 'bg-gray-200 text-black'}`}
              ></textarea>
              <button 
                type="submit"
                className={`px-4 py-2 bg-transparent border rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300 ${isDarkTheme ? 'text-white border-white' : 'text-[#1518e1] border-[#1518e1]'}`}
              >
                <AnimatedText text="Send Message" />
              </button>
            </form>
          )}
        </div>
      </div>
    );
  };

  const JoinUsPage = () => {
    return (
      <div className={`flex flex-col items-center min-h-screen w-full ${isDarkTheme ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
        <div className="absolute top-4 left-4 z-30">
          <button onClick={goToHome} className={`text-2xl font-bold hover:text-[#1518e1] ${isDarkTheme ? 'text-white' : 'text-black'}`}>
            <AnimatedText text="Cosmid Bio" />
          </button>
        </div>
        <button 
          onClick={toggleTheme} 
          className="absolute top-4 right-4 z-30 px-4 py-2 bg-transparent text-[#1518e1] border border-[#1518e1] rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300"
        >
          {isDarkTheme ? '☀️' : '🌙'}
        </button>

        <h1 className="text-4xl mb-4 mt-16"><AnimatedText text="Join Our Team" /></h1>
        <h2 className="text-2xl mb-8"><AnimatedText text="Open Positions" /></h2>
        <div className="w-full max-w-4xl px-4">
          <h3 className="text-2xl mb-4"><AnimatedText text="Core Team Member" /></h3>
          <ul className="list-disc list-inside space-y-4 mb-8 hyphenate">
            <li><AnimatedText text="Proficient in Python as well as (optionally) a systems programming language (preferably C, C++, or Rust)." /></li>
            <li><AnimatedText text="Proficient in (some) relevant Python libraries for machine learning (PyTorch, Tensorflow, Keras, scikit-learn, etc.) and life sciences." /></li>
            <li><AnimatedText text="Familiar with advanced concepts in life sciences." /></li>
            <li><AnimatedText text="Familiar with relevant technologies in software development (Git, GitHub, etc.)." /></li>
          </ul>
          <h3 className="text-2xl mb-4"><AnimatedText text="Leader - Language Processing Group" /></h3>
            <ul className="list-disc list-inside space-y-4 mb-8 hyphenate">
              <li><AnimatedText text="Proficient in Python as well as (optionally) a systems programming language (preferably C, C++, or Rust)." /></li>
              <li><AnimatedText text="Proficient in (some) relevant Python libraries for machine learning (PyTorch, Tensorflow, Keras, scikit-learn, etc.) and life sciences. " /></li>
              <li><AnimatedText text="Familiar with advanced concepts in life sciences and language processing." /></li>
              <li><AnimatedText text="A strong understanding of LLMs and relevant models as well current domain-specific research." /></li>
              <li><AnimatedText text="Familiar with relevant technologies in software development (Git, GitHub, etc.)." /></li>
            </ul>

          <p className="text-lg hyphenate">
            <AnimatedText text="We welcome any student to contribute to our projects, but we are also always in search of core team members. A position on our team comes with more responsibility and commitment to projects. If interested, please apply " />
            <a 
              href="https://docs.google.com/forms/d/e/1FAIpQLSdFhFuRXU15zPknviux5oXCgJRf3si7Qpu-KnTp0b0vn-pEng/viewform" 
              target="_blank" 
              rel="noopener noreferrer" 
              className={`text-[#1518e1] hover:underline ${isDarkTheme ? 'hover:text-blue-400' : 'hover:text-blue-600'}`}
            >
              <AnimatedText text="here" />
            </a>
            <AnimatedText text=". For general contributions, please visit our Discord." />
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <style>{globalStyles}</style>
      {currentPage === 'home' ? (
        <div className={`flex flex-col items-center justify-center min-h-screen relative overflow-hidden ${isDarkTheme ? 'bg-gray-900' : 'bg-white'}`}>
          <div className="absolute inset-0 w-full h-full z-0 filter blur-sm">
            <svg className="w-full h-full opacity-60" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <pattern id="complexPattern" width="200" height="200" patternUnits="userSpaceOnUse" patternTransform="rotate(-20) scale(1.4)">
                  <path d="M0,100 L50,0 M0,100 L50,200 M100,0 L150,100 M100,200 L150,100
                           M50,0 L150,100 M50,200 L150,100" 
                        stroke={isDarkTheme ? '#a5a7f3' : '#1518e1'} 
                        strokeWidth="1"
                        fill="none"/>
                </pattern>
              </defs>
              <rect width="100%" height="100%" fill="url(#complexPattern)" />
            </svg>
          </div>
          <canvas ref={canvasRef} className="absolute inset-0 z-10" />
          <button 
            onClick={toggleTheme} 
            className="absolute top-4 right-4 z-30 px-4 py-2 bg-transparent text-[#1518e1] border border-[#1518e1] rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300"
          >
            {isDarkTheme ? '☀️' : '🌙'}
          </button>
          <div className={`relative z-20 text-center flex flex-col items-center transition-opacity duration-1000 ease-in ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            <h1 className={`text-4xl md:text-6xl lg:text-8xl font-bold mb-2 font-sans flex ${isDarkTheme ? 'text-white' : 'text-black'}`}>
              {titleWords.map((word, wordIndex) => (
                <React.Fragment key={wordIndex}>
                  {wordIndex > 0 && <span className="mx-2.5"> </span>}
                  <AnimatedText text={word} className="inline-block" isTitle={true} />
                </React.Fragment>
              ))}
            </h1>
            <AnimatedText 
              text={subtitle}
              className={`text-xl md:text-2xl font-sans mb-8 ${isDarkTheme ? 'text-gray-300' : 'text-black'}`}
            />
            <div className="flex flex-wrap justify-center space-x-4">
              <button onClick={goToNewPage} className={`px-4 py-2 mb-2 bg-transparent border rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300 hover:-translate-y-1 whitespace-nowrap ${isDarkTheme ? 'text-white border-white' : 'text-[#1518e1] border-[#1518e1]'}`}>
                <AnimatedText text="Projects" />
              </button>
              <button onClick={goToJoinUsPage} className={`px-4 py-2 mb-2 bg-transparent border rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300 hover:-translate-y-1 whitespace-nowrap ${isDarkTheme ? 'text-white border-white' : 'text-[#1518e1] border-[#1518e1]'}`}>
                <AnimatedText text="Join Us" />
              </button>
              <button onClick={goToContactPage} className={`px-4 py-2 mb-2 bg-transparent border rounded hover:bg-white hover:bg-opacity-20 transition-all duration-300 hover:-translate-y-1 whitespace-nowrap ${isDarkTheme ? 'text-white border-white' : 'text-[#1518e1] border-[#1518e1]'}`}>
                <AnimatedText text="Contact Us" />
              </button>
            </div>
          </div>
        </div>
      ) : currentPage === 'new' ? (
        <NewPage />
      ) : currentPage === 'contact' ? (
        <ContactPage />
      ) : (
        <JoinUsPage />
      )}
    </>
  );
}

export default App;
